import Vue from "vue";
import AccountService from "./AccountService";

export default {
    getCustomerSignupOriginStats(startDate = undefined, endDate = undefined) {
        let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stats/customer/signupplatforms";
        let options = AccountService.getSessionHeaders();
        let dataOptions = {
            start_date: startDate,
            end_date: endDate
        };

        return Vue.axios.post(apiMethodUrl, dataOptions, options);
    },

    getCustomerReferralStats(startDate = undefined, endDate = undefined) {
        let apiMethodUrl = process.env.VUE_APP_ROOT_API + "/stats/customer/referrals";
        let options = AccountService.getSessionHeaders();
        let dataOptions = {
            start_date: startDate,
            end_date: endDate
        };

        return Vue.axios.post(apiMethodUrl, dataOptions, options);
    }
};
