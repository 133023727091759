<template>
  <div class="dashboard">
    <div
      class="
        d-flex
        justify-content-between
        flex-wrap flex-md-nowrap
        align-items-center
        pt-3
        pb-2
        mb-3
        border-bottom
      "
    >
      <h1 class="h2">Dashboard</h1>
    </div>

    <table class="table">
      <tr>
        <th>Hero Image</th>
        <td>1920px x 800px</td>
      </tr>
      <tr>
        <th>Blog and Media Images (NOT THUMBS)</th>
        <td>600px width</td>
      </tr>
      <tr>
        <th>Blog and Media Images (NOT THUMBS)</th>
        <td>600px width</td>
      </tr>
      <tr>
        <th>Blog Thumbnails</th>
        <td>800px x 533px</td>
      </tr>
      <tr>
        <th>Gear Thumbnails</th>
        <td>800px x 600px</td>
      </tr>
      <tr>
        <th>Instructor Profile Images</th>
        <td>600px x 600px</td>
      </tr>
      <tr>
        <th>Home Content Featured Image</th>
        <td>942px width, height: dynamic</td>
      </tr>
    </table>

    <h2>Found out From Referral Stats</h2>
    <form class="form-inline date-range-form" v-on:submit.prevent="filterReferralStats">
      <div class="form-group">
        <label for="subscribed_on_start_date">Subscribed Start Date:</label>
        <datepicker placeholder="Subscribed Start Date" v-model="subscribed_on_start_date"
          name="subscribed_on_start_date" input-class="form-control"></datepicker>
      </div>
      <div class="form-group">
        <label for="subscribed_on_end_date">Subscribed End Date:</label>
        <datepicker placeholder="Subscribed End Date" v-model="subscribed_on_end_date" name="subscribed_on_end_date"
          input-class="form-control"></datepicker>
      </div>
      <div class="form-group ml-2">
        <button class="btn btn-sm btn-primary" type="submit">Apply</button>
      </div>
    </form>
    <table class="table table-sm table-striped table-dark mt-2">
      <tbody>
        <tr>
          <td scope="row">Found out From:</td>
          <td>Count</td>
        </tr>
        <tr v-for="referral in referrals" v-bind:key="referral.found_out_from">
          <td scope="row">{{ referral.found_out_from }}</td>
          <td>{{ referral.count }}</td>
        </tr>
      </tbody>
    </table>

    <h2>Signed up from Stats</h2>
    <form class="form-inline date-range-form" v-on:submit.prevent="filterSignUpOriginStats">
      <div class="form-group">
        <label for="subscribed_on_start_date">Subscribed Start Date:</label>
        <datepicker placeholder="Subscribed Start Date" v-model="subscribed_on_start_date"
          name="subscribed_on_start_date" input-class="form-control"></datepicker>
      </div>
      <div class="form-group">
        <label for="subscribed_on_end_date">Subscribed End Date:</label>
        <datepicker placeholder="Subscribed End Date" v-model="subscribed_on_end_date" name="subscribed_on_end_date"
          input-class="form-control"></datepicker>
      </div>
      <div class="form-group ml-2">
        <button class="btn btn-sm btn-primary" type="submit">Apply</button>
      </div>
    </form>
    <table class="table table-sm table-striped table-dark mt-2">
      <tbody>
        <tr>
          <td scope="row">Platform</td>
          <td>Count</td>
        </tr>
        <tr v-for="platform in platforms" v-bind:key="platform.sign_up_platform">
          <td scope="row">{{ platform.sign_up_platform }}</td>
          <td>{{ platform.count }}</td>
        </tr>
      </tbody>
    </table>

    <table class="table">
      <thead>
        <tr>
          <th>ID</th>
          <th>API VERSION</th>
          <th>TYPE</th>
          <th>EVENT DATA</th>
          <th>CREATED ON</th>
        </tr>
      </thead>
      <tbody v-if="events.length > 0">
        <tr v-for="event in events" v-bind:key="event.id">
          <td>{{ event.id }}</td>
          <td>{{ event.api_version }}</td>
          <td>{{ event.type }}</td>
          <td>
            <pre>{{ event.data }}</pre>
          </td>
          <td>{{ event.created_on }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import Datepicker from "vuejs-datepicker";
import StatsService from "../services/StatsService";

export default {
  components: {
    datepicker: Datepicker,
  },

  data: function () {
    return {
      events: [],
      subscribed_on_start_date: undefined,
      subscribed_on_end_date: undefined,
      platforms: {},
      referrals: {},
    };
  },

  methods: {
    goToPage(page) {},

    filterSignUpOriginStats() {
      StatsService.getCustomerSignupOriginStats(this.subscribed_on_start_date, this.subscribed_on_end_date).then(response => {
        let data = response.data;

        this.platforms = data.content;
      });
    },

    filterReferralStats() {
      StatsService.getCustomerReferralStats(this.subscribed_on_start_date, this.subscribed_on_end_date).then(response => {
        let data = response.data;

        this.referrals = data.content;
      });
    },
  },

  async created() {
    let token = this.$cookies.get("poolfit_admin_session");

    if (token !== null) {
      let options = {
        headers: { "X-Access-Token": token },
      };
     
      let eventsResponse = await this.$http
        .get(process.env.VUE_APP_ROOT_API + "/events", options);
      this.events = eventsResponse.data;

      await this.filterSignUpOriginStats();
      await this.filterReferralStats();
    }
  },
};
</script>
<style scoped></style>
